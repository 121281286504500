export default function track(eventName, payload) {
  const trackEvent = new CustomEvent('trailhead_track', {
    bubbles: true,
    composed: true,
    detail: {
      eventName,
      payload,
    },
  });
  document.dispatchEvent(trackEvent);
}
