// trailhead nav events, for continuity of measurements
import track from '../lib/tracking/track';

window.digitalNavTracking = (data) => {
  let { detail, property } = data;

  // only handle custEv_navigationClick
  if(detail.event !== "custEv_navigationClick") {
    return
  }

  // custEv_navigationClick not supported by schema using nav_clicked
  let eventName = 'nav_clicked';

  let payload = {
    // set in _javascript_packs
    "lang": window.locale,
    // no stable property across locales... needs improvement
    "label": detail.navItem,
    // property is trailhead or community
    // navType is global or context:static
    "pageLocation": `digital_nav_${property}_${detail.navType}`
  };

  track(eventName, payload);
}

document.addEventListener('www_track', (e) => {
  try {
    if(window.dataLayer) {
      window.dataLayer.push(e.detail);
    }
  } catch {
    console.log('Could not push event detail to GTM');
  }

  let data = {
    detail: e.detail,
    property: window?.Trailhead?.property,
    location: e.detail.navType
  }

  window.digitalNavTracking(data);
});

document.addEventListener('xsf_track', (e) => {
  let data = {
    detail: e.detail,
    property: window?.Trailhead?.property,
    location: e.detail.navType
  }

  window.digitalNavTracking(data);
});
